var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      attrs: {
        block: "",
        color: "primary",
        to: {
          name: "invitation-accept",
          params: { invid: this.$route.params.invid },
        },
      },
    },
    [_vm._v("continue")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }